<template>
  <tr class="ranking-item">
    <th class="col -rank">{{ rankingItem.rank }}</th>
    <td class="col -hero">
      <div
        v-for="(hero, i) in rankingItem.heroes"
        :key="i"
        :class="heroClass"
        class="hero"
      >
        <span class="series" v-html="getTitle(hero.seriesId)" />
        <span class="name">{{ hero.name }}</span>
      </div>
    </td>
    <td class="col -votes">{{ formatVotes(rankingItem.votes) }}</td>
  </tr>
</template>

<script>
import { delimit } from '@libraries/util/Math'
import { getLocaleByName } from '@/constants/locale'

export default {
  name: 'ranking-item',

  props: {
    rankingItem: {
      type: Object,
      required: true
    },
    titles: {
      type: Array,
      required: true
    }
  },

  computed: {
    locale () {
      return this.$route.params.lang
    },

    heroClass () {
      const isPair = this.rankingItem.type === 'pair'
      return {
        '-single': !isPair,
        '-pair': isPair
      }
    }
  },

  methods: {
    formatVotes (votes) {
      const localeKey = getLocaleByName(this.locale).value.alias
      return delimit(votes,localeKey)
    },

    getTitle (id) {
      // MEMO: 「/」 を改行に置換する処理をコメントアウト
      // let title = this.titles.find(title => title.id === id).title
      // const replacement = (this.locale === 'ja-jp' || this.locale === 'zh-tw')  ? '／' : ' / '
      // const index = title.indexOf(replacement)
      // return index === -1
      //         ? title
      //         : title.substr(0, index) + '<br>' + title.substr(index + replacement.length)
      return this.titles.find(title => title.id === id).title
    },
  }
}
</script>

<style lang="scss" scoped>
.ranking-item {
  background: url(#{$img-path}result/bg_line.png) no-repeat center top;
  $self: #{&};
  
  @at-root {
    // IEはtrのcenter配置を誤解釈する
    .-ie {
      #{$self} {
        background-position: 37px top;
      }
    }
  }

  > .col {
    color: $color-sub;
    padding-top: 18px;
    padding-bottom: 10px;
    letter-spacing: $ls-base;
    vertical-align: middle;

    &.-rank {
      padding-top: 36px;
      padding-bottom: 27px;
      text-align: center;
    }
    &.-hero {
      padding-left: 4px;
    }
    &.-votes {
      text-align: right;
      padding-right: 7px;
    }
  }
    .hero {
      display: flex;
      flex-direction: column;

      + .hero {
        margin-top: 16px;
      }

      &.-pair {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      > .series {
        color: $color-em2;
        font-size: $fs-base;
        letter-spacing: $ls-base;
        // white-space: nowrap;
      }
      > .name {
        color: $color-sub;
        font-size: $fs-middle;
        letter-spacing: $ls-base;
      }
    }
}
</style>