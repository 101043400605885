<template>
  <ul class="theme-nav">
    <li
      v-for="themeItem in themeList"
      :key="themeItem.id"
      :class="{ '-selected': themeType === themeItem.id }"
      class="nav-item"
    >
      <a
        :href="`#result${themeItem.id}`"
        @click.prevent="$emit('click', themeItem.id)"
        v-html="themeItem.name"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ThemeNav',

  props: {
    themeType: {
      type: Number,
      required: true
    },
    themeList: {
      type: Array,
      required: true
    }
  },

  computed: {
  }
}
</script>

<style lang="scss" scoped>
.theme-nav {
  width: 570px;

  > .nav-item {
    $lh: lh(60, $fs-middle);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: $fs-middle;
    // line-height: lh(60, $fs-middle);
    line-height: 1.4;
    padding: 7px;

    &:before {
      content: '';
      display: block;
      background: url(#{$img-path}result/icon_bullet.png) no-repeat left top;
      width: 26px;
      height: 26px;
      margin-right: 15px;
      margin-bottom: -4px;
      flex: 0 0 26px;
    }

    + .nav-item {
      margin-top: 12px;
    }

    > a {
      color: #010000;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &.-selected {
      background-color: rgba(#ffffe0, 0.5);
      pointer-events: none;
      font-weight: 600;

      > a {
        text-decoration: none;
      }
    }
  }
}
</style>