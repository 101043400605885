<template>
  <div class="resultlist">
    <confetti />
    <visual
      :confetti="false"
      class="resultlist-visual"
    />
    <section class="main-content">
      <h2 class="title">
        <img
          :src="titleImageSrc"
          width="750"
          height="248"
          :alt="$t('result001')"
        >
      </h2>
      <div class="content-inner">
        <theme-nav
          :themeList="themeList"
          :themeType="themeType"
          @click="handleNavClick"
          class="-top"
        />

        <div
          ref="ranking-table"
          class="ranking-table"
        >
          <table class="table-main">
            <thead>
              <tr>
                <th v-html="$t('result006')" class="col -rank" />
                <th v-html="$t('result007')" class="col -hero" />
                <th v-html="$t('result009')" class="col -votes" />
              </tr>
            </thead>
            <tbody>
              <ranking-row
                v-for="(rankingItem, i) in themesResults"
                :key="i"
                :rankingItem="rankingItem"
                :titles="titles"
              />
            </tbody>
          </table>
        </div>

        <theme-nav
          :themeList="themeList"
          :themeType="themeType"
          @click="handleNavClick"
          class="-bottom"
        />
      </div>
    </section>

    <div class="misc-content">
      <back-button @click="goBack" />
    </div>

    <div class="page-footer">
      <sns-share
        shareText="common002"
        :replaceText="replaceText"
      />
      <app-download />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Confetti from '@components/global/confetti.vue'
import Visual from '@components/global/visual.vue'
import BackButton from '@components/global/back-button.vue'
import ThemeNav from '@components/result/theme-nav.vue'
import RankingRow from '@components/result/ranking-item.vue'
import SnsShare from '@components/global/sns-share.vue'
import AppDownload from '@components/global/app-download.vue'
import Api from '@libraries/api/'
import RequestHeroes from '@requests/heroes'
import RequestTitles from '@requests/titles'

export default {
  name: 'ResultList',

  components: {
    Confetti,
    Visual,
    BackButton,
    ThemeNav,
    RankingRow,
    SnsShare,
    AppDownload
  },

  props: {
    locale: {
      type: String,
      required: true
    },
    themeType: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      titles: null,
      results: null
    }
  },

  computed: {
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('theme', { themeList: 'getThemes' }),
    ...mapGetters('result', { isResultOpen: 'isOpen', resultList: 'getResult'}),

    titleImageSrc () {
      return require(`@images/result/${this.locale}/main_title.png`)
    },

    theme () {
      if (!this.results) {
        return
      }
      return this.results.find((theme) => {
        return theme.theme.id === this.themeType ? theme : null
      })
    },

    themesTheme () {
      if (!this.theme) {
        return
      }
      return this.theme.theme
    },

    themesResults () {
      if (!this.theme) {
        return
      }
      return this.theme.results
    },

    replaceText () {
      return { url: `${location.origin}/${this.locale}/result/${this.themeType}` }
    },
  },

  created () {
    if (!this.isResultOpen) {
      this.$router.replace({name: 'Top'})
    }
  },

  async mounted () {
    await this.getTitles()
    await Promise.all([
      await this.getHeroes(),
      await sleep(this.loaderConfig.minDisplayDuration)
    ])
    this.$nextTick(async () => {
      await this.hideLoader()
    })

  },

  methods: {
    ...mapActions('loader', { hideLoader: 'hide' }),
    ...mapActions('result', { initResult: 'init' }),

    async getTitles () {
      await Api.call(new RequestTitles({ locale: this.locale }))
        .then((titles) => {
          this.titles = titles
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    async getHeroes () {
      await Api.call(new RequestHeroes({ locale: this.locale }))
        .then((_heroes) => {
          this.results = this.resultList.map((result) => {
            const theme = this.themeList.find(theme => theme.id === result.themeId)
            const results = result.results.map((r) => {
              const rank = r.rank
              const votes = r.votes
              const heroes = r.hero_ids.map(heroId => _heroes.find(hero => hero.id === heroId))
              return { rank, votes, heroes }
            })
            return { theme, results }
          })
        })
        .catch(({ error }) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({ name: 'Error' })
        })
    },

    goToList () {
      this.$router.push({
        name: 'ResultList',
        params: {
          themeType: 1
        }
      })
    },

    goBack () {
      this.$router.push({ name: 'Top' })
    },

    handleNavClick (themeId) {
      if (themeId === this.themeType) {
        return
      }

      this.$scrollTo(this.$refs['ranking-table'], 400, {
        onDone: () => {
          this.$router.replace({ path: `/${this.locale}/result/${themeId}` })
        }
      })
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/result.yaml"></i18n>

<style lang="scss" scoped>
.resultlist {
  .confetti {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 600px;
    z-index: 0;
  }
  .resultlist-visual {
    position: relative;
    z-index: 1;
  }
  .main-content {
    position: relative;
    width: 736px;
    margin-left: auto;
    margin-right: auto;
    background:
      url(#{$img-path}result/main_bg_top.png) no-repeat center top,
      url(#{$img-path}result/main_bg_bottom.png) no-repeat center bottom;
    padding-top: 174px;
    padding-bottom: 184px;

    > .title {
      position: absolute;
      top: -80px;
      margin-left: -7px;
      margin-right: -7px;
    }
  }
    .content-inner {
      background: url(#{$img-path}result/main_bg_repeat.png) repeat-y center top;
      padding-left: 33px;
      padding-right: 33px;
      padding-top: 18px;

      > .theme-nav {
        margin-left: auto;
        margin-right: auto;

        &.-top {
          margin-bottom: 26px;
        }
        &.-bottom {
          margin-top: 42px;
        }
      }
    }

    .ranking-table {
      border-top: 4px solid rgba(#ffffe0, 0.8);
      border-bottom: 4px solid rgba(#ffffe0, 0.8);
      background-color: rgba(#262213, 0.75);
      padding-left: 13px;
      padding-right: 13px;
      width: 100%;

      & > .table-main {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;

        > thead {
          > tr {
            > .col {
              color: $color-sub;
              font-size: $fs-middle;
              font-weight: 400;
              letter-spacing: $ls-base;
              padding-top: 27px;
              padding-bottom: 21px;
              line-height: 1;
              vertical-align: middle;
            }
            > .col.-rank {
              text-align: center;
              width: 148px;
              word-break: break-all;
            }
            > .col.-hero {
              text-align: left;
              padding-left: 4px;
            }
            > .col.-votes {
              text-align: right;
              width: 134px;
              padding-right: 7px;
            }
          }
        }
      }
    }

  .misc-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 18px;
    padding-bottom: 70px;

    > .back-button {
      
    }
  }
}
</style>
