const _max = Math.max
const _min = Math.min
const _floor = Math.floor

/**
 * 数字をカンマ入りに変更する
 * @param {number} num
 * @return {string}
 */
export function delimit (num, locale) {
  if (locale === 'de-DE' || locale === 'es-ES' || locale === 'es-MX' || locale === 'fr-CA' || locale === 'fr-FR') {
    return `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }
  if (locale === 'it-IT' || locale === 'pt-BR') {
    return `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  if (locale === 'en-US' || locale === 'en-GB' || locale === 'ja-JP' || locale === 'zh-TW' ){
    return `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * ランダムな整数を返す
 * @param {number} min - 最小値
 * @param {number} max - 最大値
 */
export function getRandomInt (min, max) {
  return _floor(Math.random() * (max - min + 1) + min)
}

/**
 * 範囲内の乱数を返す
 * @param {number} min - 最小値
 * @param {number} max - 最大値
 */
export function getRandomNumber (min, max) {
  return Math.random() * (max - min) + min
}

/**
 * 下限値・上限値に収まる値を返す
 * @param {number} x - 実数
 * @param {number} low - 最小値
 * @param {number} high - 最大値
 * @return {number}
 */
export function clamp (x, low, high) {
  return _min(_max(x, low), high)
}

/**
 * ラップアラウンドする
 * @param {number} x - 実数
 * @param {number} low - 最小値
 * @param {number} high - 最大値
 * @return {number}
 */
export function wrap (x, low, high) {
  const n = (x - low) % (high - low)
  return (n >= 0) ? (n + low) : (n + high)
}