<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
    class="confetti"
   />
</template>

<script>
import * as PIXI from 'pixi.js'
import ConfettiParticles from '@libraries/ConfettiParticles'

export default {
  name: 'ConfettiCanvas',

  data () {
    return {
      isPlaying: false
    }
  },
  props: {
    width: {
      type: Number,
      default: 750
    },
    height: {
      type: Number,
      default: 400
    },
    config: {
      type: Object,
      default: null
    },
    colorTable: {
      type: Array,
      default: null
    }
  },
  computed: {
    screenWidth () {
      return this.app.screen.width
    },
    screenHeight () {
      return this.app.screen.height
    }
  },
  mounted () {
    const elm = this.$refs.canvas
    this.app = new PIXI.Application({
      view: elm,
      width: elm.clientWidth,
      height: elm.clientHeight,
      forceFXAA: true,
      antialias: true,
      transparent: true,
    })
    this.confettiParticles = new ConfettiParticles({ config: this.config, colorTable: this.colorTable })
    this.confettiParticles.create()
    this.confettiParticles.setScreenSize(this.screenWidth, this.screenHeight)
    this.app.stage.addChild(this.confettiParticles)

    // @NOTE playタイミングを変えたい場合は調整
    this.play()
  },
  destroyed () {
    this.stop()
    this.app.destroy(true, { children: true, texture: true, baseTexture: true })
  },
  methods: {
    play () {
      if (this.isPlaying) {
        return
      }
      this.isPlaying = true
      this.app.ticker.add(this.update, this)
    },
    stop () {
      if (!this.isPlaying) {
        return
      }
      this.isPlaying = false
      this.app.ticker.remove(this.update, this)
    },
    update (df) {
      const dt = 1 / this.app.ticker.FPS
      this.confettiParticles.update(dt, df)
    }
  }
}
</script>

<style lang="scss" scoped>
.canvas {
  width: $base-w;
  height: 400px;
}
</style>