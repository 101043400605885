import Request, { Methods } from '@libraries/request/'
import ModelHero from '@models/hero'
import { basePath } from './config'

export default class Heroes extends Request {
  constructor(options) {
    super()
    this.locale = options.locale || null
    this.series = options.seriesId || null
  }

  get path() {
    return basePath + 'json/heroes.json'
  }

  get method() {
    return Methods.get
  }

  get parameters() {
    const params = {}
    return params
  }

  decode({ data }) {
    let res = []
    for (const key in data[this.locale]) {
      const _data = {}
      _data.hero_id = Number(key)
      _data.name = data[this.locale][key]
      const hero = new ModelHero(_data, this.locale)
      res.push(hero)
    }
    return this.series ? res.filter(h => h.seriesId === this.series) : res
  }

  decodeError(error) {
    return { error: error }
  }
}