<template>
  <aside class="app-download">
    <div class="inner">
      <h2 class="app-icon">
        <img
          src="@images/app-download/icon.png"
          alt="Download"
          width="90"
          height="90"
        >
      </h2>
      <ul class="store-list">
        <li class="list-item">
          <a
            :href="appStore"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="appStoreSrc"
              alt="App Store link"
              height="90"
            >
          </a>
        </li>
        <li class="list-item">
          <a
            :href="googlePlay"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="googlePlaySrc"
              alt="Google Play link"
              height="90"
            >
          </a>
        </li>
      </ul>
    </div>
    <p class="trademark" v-html="$t('top048')" />
  </aside>
</template>

<script>
const appStoreLinks = {
  'ja-jp': 'https://itunes.apple.com/jp/app/id1181774280',
  'en-us': 'https://itunes.apple.com/us/app/id1181774280',
  'en-gb': 'https://itunes.apple.com/gb/app/id1181774280',
  'de-de': 'https://itunes.apple.com/de/app/id1181774280',
  'fr-fr': 'https://itunes.apple.com/fr/app/id1181774280',
  'fr-ca': 'https://itunes.apple.com/ca/app/id1181774280?l=fr',
  'es-es': 'https://itunes.apple.com/es/app/id1181774280',
  'es-mx': 'https://itunes.apple.com/mx/app/id1181774280',
  'it-it': 'https://itunes.apple.com/it/app/id1181774280',
  'zh-tw': 'https://itunes.apple.com/tw/app/id1181774280',
  'pt-br': 'https://itunes.apple.com/pt/app/id1181774280',
}
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.nintendo.zaba'

export default {
  name: 'AppDownload',

  components: {
  },

  data () {
    return {
    }
  },

  computed: {
    locale () {
      return this.$route.params.lang
    },

    appStore () {
      if (!this.locale) {
        return
      }

      return appStoreLinks[this.locale]
    },

    appStoreSrc () {
      if (!this.locale) {
        return
      }
      return require(`@images/app-download/${this.locale}/appstore-lrg.png`)
    },

    googlePlay () {
      return googlePlayLink
    },

    googlePlaySrc () {
      if (!this.locale) {
        return
      }
      return require(`@images/app-download/${this.locale}/google-play-badge.png`)
    }
  },

  methods: {
  }
}
</script>

<i18n lang="yaml" src="@/locales/top.yaml"></i18n>

<style lang="scss" scoped> 
.app-download {
  overflow: hidden;

  > .inner {
    /*
    width: 694px;
    margin-left: auto;
    margin-right: auto;
    */
    display: flex;
    justify-content: center;
  }
  > .inner > .app-icon {
    margin-right: 32px;
  }

  > .trademark {
    font-size: $fs-base;
    color: $color-sub;
    line-height: lh(36, $fs-base);
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 32px;
  }
}
  .store-list {
    display: flex;

    > .list-item + .list-item {
      margin-left: 19px;
    }
  }
</style>