<template>
  <button
    @click.prevent="handleClick"
    class="back-button"
  >
    <img :src="src" alt="back">
  </button>
</template>

<script>
import ButtonMixin from '@/mixins/Button'

export default {
  name: 'BackButton',

  mixins: [ ButtonMixin ],

  computed: {
    locale () {
      return this.$route.params.lang
    },

    src () {
      return require(`@images/common/${this.locale}/btn_back.png`)
    }
  },

  methods: {
    handleClick () {
      this.animateButton()
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button {
}
</style>