import * as PIXI from 'pixi.js'
import nestedObjectAssign from 'nested-object-assign'
import { getRandomInt } from '@libraries/util/Math'

const { PI, sin } = Math

/**
 * Generate confetti particles
 * @class
 * @extends {PIXI.Container}
 * @param {object} data
 */
export default class ConfettiParticles extends PIXI.Container {
  constructor (data = {}) {
    super()

    this.defaultConfig = {
      confettiSize: {
        minWidth: 3,
        maxWidth: 8,
        minHeight: 8,
        maxHeight: 12
      },
      fallSpeed: 3,
      driftSpeed: 1,
      flipSpeed: 0.3,
      rotateSpeed: 0.02,
      limitNumber: 300,
      respawnInterval: 20, // ms
    }

    this.defaultColorTable = [
      0xE1615E, //pink
      0xF92C21, //aka
      0xFDE654, //kiiro
      0xDDD07B, //bege 
      0xE5B17F, //orange
      0xE0581E, //orange2
      0xE6EE56, //uguisu
      0xACFF13, //kimidori
      0xC1E684 //usukimidori
    ]

    this.config = nestedObjectAssign({}, this.defaultConfig, data.config)
    this.colorTable = data.colorTable || this.defaultColorTable
    this.elapsedTime = 0
    this.displayNumber = 0
    this.isPlaying = false

    this.confettiList = null
  }

  setScreenSize (width, height) {
    this.screenWidth = width
    this.screenHeight = height
  }

  isNumberLimit () {
    return this.displayNumber >= this.config.limitNumber
  }

  create () {
    const { minWidth, minHeight, maxWidth, maxHeight } = this.config.confettiSize
    this.confettiList = new Array(this.config.limitNumber).fill().map((nil, i) => {
      const width = getRandomInt(minWidth, maxWidth)
      const height = getRandomInt(minHeight, maxHeight)
      const color = this.colorTable[getRandomInt(0, this.colorTable.length - 1)]
      const confetti = new PIXI.Graphics()
        .beginFill(color)
        .drawRect(0, 0, width, height)
        .endFill()
      confetti.id = i
      confetti.pivot.set(width * 0.5, height * 0.5)
      confetti.visible = false
      confetti.halfWidth = confetti.width * 0.5
      confetti.halfHeight = confetti.height * 0.5
      this.addChild(confetti)
      return confetti
    })
  }

  step (target, dt) {
    target.x += target.xSpeed * dt
    target.y += target.ySpeed * dt
    target.rotation += PI * 2 * target.rotationSpeed * dt
    target.flip += target.flipSpeed * dt
    target.scale.set(sin(target.flip), 1)
  }

  respawn (target) {
    target.position.set(Math.random() * this.screenWidth, 0)
    target.xSpeed = (Math.random() * 2 - 1) * this.config.driftSpeed
    target.ySpeed = (Math.random() * 0.5 + 0.5) * this.config.fallSpeed
    target.rotationSpeed = (Math.random() * 2 - 1) * this.config.rotateSpeed
    target.flipSpeed = (Math.random() * 4 - 1) * this.config.flipSpeed
    target.flip = 0
    target.visible = true
    this.displayNumber++
  }

  checkIsOver (target) {
    if (
      (target.x - target.halfWidth) <= 0 || 
      (target.x - target.halfWidth) >= this.screenWidth ||
      (target.y - target.halfHeight) >= this.screenHeight
    ) {
      target.visible = false
      this.displayNumber--
    }
  }

  update (dt, df) {
    // const dt = 1 / this.app.ticker.FPS
    this.elapsedTime += dt * 1000

    this.confettiList.forEach((confetti) => {
      if (!confetti.visible) {
        return
      }
      this.checkIsOver(confetti)
      this.step(confetti, df)
    })

    if (this.elapsedTime >= this.config.respawnInterval && !this.isNumberLimit()) {
      const newConfetti = this.confettiList.find(confetti => !confetti.visible)
      if (!newConfetti) {
        return
      }
      this.respawn(newConfetti)
      this.elapsedTime = 0
    }
  }

}