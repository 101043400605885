import { render, staticRenderFns } from "./result-list.vue?vue&type=template&id=50da20f3&scoped=true&"
import script from "./result-list.vue?vue&type=script&lang=js&"
export * from "./result-list.vue?vue&type=script&lang=js&"
import style0 from "./result-list.vue?vue&type=style&index=0&id=50da20f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50da20f3",
  null
  
)

/* custom blocks */
import block0 from "@/locales/result.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fviews%2Fresult-list.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports